<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <section class="gray pt-4">
        <div class="container">
          <small class="float-end">Updated at 2022-11-02</small>
          <h2>Terms And Conditions</h2>
          <div class="card">
            <div class="card-body">
              <p>
                Realhub hereby grants you access to https://realhub.co.ke/ (“the
                Website”) and invites you to purchase the services offered here.</p>
              <h4 class="mt-4">
                Definitions and key terms
              </h4>
              <p>
                To help explain things as clearly as possible in this
                Disclaimer, every time any of these terms are referenced, are
                strictly defined as:</p>
              <p>
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide analytics, remember information about you such as your
                language preference or login information.
              </p>
              <p>
                Company: when this policy mentions “Company,” “we,” “us,” or
                “our,” it refers to Realhub Investments Ltd, 2nd Floor, Miranje House, P.o Box 70886-00400, Ruai, Nairobi. that is
                responsible for your information under this Disclaimer.
              </p>
              <p>
                Service: refers to the service provided by Realhub as described
                in the relative terms (if available) and on this platform.
              </p>
              <p>
                Website: Realhub.&quot;’s&quot; site, which can be accessed via
                this URL: https://realhub.co.ke/
              </p>
              <p>
                You: a person or entity that is registered with Realhub to use
                the Services.
              </p>
              <h4 class="mt-4">
                Limited liability
              </h4>
              <p>
                Realhub endeavours to update and/or supplement the content of
                the website on a regular basis. Despite our care and attention, 
                content may be incomplete and/or incorrect.
              </p>
              <p>
                The materials offered on the website are offered without any
                form of guarantee or claim to their correctness. These materials 
                can be changed at any time without prior notice from Realhub.
              </p>
              <p>
                Particularly, all prices on the website are stated subject to
                typing and programming errors. No liability is assumed for the 
                implications of such errors. No agreement is concluded on the
                basis of such errors.
              </p>
              <p>
                Realhub shall not bear any liability for hyperlinks to websites
                or services of third parties included on the website. From our 
                website, you can visit other websites by following hyperlinks to
                such external sites. While we strive to provide only quality
                links 
                to useful and ethical websites, we have no control over the
                content and nature of these sites. These links to other websites
                do 
                not imply a recommendation for all the content found on these
                sites. Site owners and content may change without notice and 
                may occur before we have the opportunity to remove a link which
                may have gone ‘bad’.
              </p>
              <p>
                Please be also aware that when you leave our website, other
                sites may have different privacy policies and terms which are 
                beyond our control. Please be sure to check the Privacy Policies
                of these sites as well as their &quot;Terms of Service&quot;
                before 
                engaging in any business or uploading any information.
              </p>
              <h4 class="mt-4">
                Links to Other Websites Disclaimer
              </h4>
              <p>
                This Disclaimer applies only to the Services. The Services may
                contain links to other websites not operated or controlled by 
                Realhub. We are not responsible for the content, accuracy or
                opinions expressed in such websites, and such websites are not 
                investigated, monitored or checked for accuracy or completeness
                by us. Please remember that when you use a link to go from 
                the Services to another website, our Privacy Policy is no longer
                in effect. Your browsing and interaction on any other website, 
                including those that have a link on our platform, is subject to
                that website’s own rules and policies. Such third parties may
                use 
                their own cookies or other methods to collect information about
                you. If You click on a third party link, You will be directed to
                that 
                third party&apos;s site. We strongly advise You to review the
                Privacy Policy and Terms of every site You visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or 
                services.
              </p>
              <h4 class="mt-4">
                Errors and Omissions Disclaimer
              </h4>
              <p>
                Realhub is not responsible for any content, code or any other
                imprecision.
              </p>
              <p>
                Realhub does not provide warranties or guarantees.
              </p>
              <p>
                In no event shall Realhub be liable for any special, direct,
                indirect, consequential, or incidental damages or any damages 
                whatsoever, whether in an action of contract, negligence or
                other tort, arising out of or in connection with the use of the
                Service 
                or the contents of the Service. Realhub reserves the right to
                make additions, deletions, or modifications to the contents on
                the 
                Service at any time without prior notice.
              </p>
              <h4 class="mt-4">
                General Disclaimer
              </h4>
              <p>
                The Realhub Service and its contents are provided &quot;as
                is&quot; and &quot;as available&quot; without any warranty or
                representations of any 
                kind, whether express or implied. Realhub is a distributor and
                not a publisher of the content supplied by third parties; as
                such, 
                Realhub exercises no editorial control over such content and
                makes no warranty or representation as to the accuracy,
                reliability 
                or currency of any information, content, service or merchandise
                provided through or accessible via the Realhub Service. Without 
                limiting the foregoing, Realhub specifically disclaims all
                warranties and representations in any content transmitted on or
                in 
                connection with the Realhub Service or on sites that may appear
                as links on the Realhub Service, or in the products provided as 
                a part of, or otherwise in connection with, the Realhub Service,
                including without limitation any warranties of merchantability, 
                fitness for a particular purpose or non-infringement of third
                party rights. No oral advice or written information given by
                Realhub or 
                any of its affiliates, employees, officers, directors, agents,
                or the like will create a warranty. Price and availability
                information is 
                subject to change without notice. Without limiting the
                foregoing, Realhub does not warrant that the Realhub Service
                will be 
                uninterrupted, uncorrupted, timely, or error-free.
              </p>
              <h4 class="mt-4">
                Copyright Disclaimer
              </h4>
              <p>
                All intellectual property rights concerning these materials are
                vested in Realhub. Copying, distribution and any other use of 
                these materials is not permitted without the written permission
                of Realhub, except and only to the extent otherwise provided in 
                regulations of mandatory law (such as the right to quote),
                unless otherwise stated for certain materials.
              </p>
              <h4 class="mt-4">
                Legal Disclosure
              </h4>
              <p>
                The materials, any comments or information provided by Realhub
                are for educational purposes only and nothing conveyed or 
                provided should be considered legal, accounting or tax advice.
                You are responsible for whatever you may do with the 
                information you obtain from Realhub. As such, by visiting and
                using this website you acknowledge and agree that you have 
                been assessed by a qualified law expert (i.e your attorney) who
                has given you consent to take part in any legal activity. Please 
                contact your own attorney, accountant or tax professional with
                any specific questions you have related to the information 
                provided that are of legal, accounting or tax nature.
              </p>
              <h4 class="mt-4">
                Educational Disclosure
              </h4>
              <p>
                Any Information provided by Realhub is for educational purposes
                only, and is not to be interpreted as a recommendation for a 
                specific treatment plan, product, or course of action. Realhub
                is a distributor and not a publisher of the content supplied by
                third 
                parties; as such, Realhub exercises no editorial control over
                such content and makes no warranty or representation as to the 
                accuracy, reliability or currency of any information or
                educational content provided through or accessible via Realhub.
                Without 
                limiting the foregoing, Realhub specifically disclaims all
                warranties and representations in any content transmitted on or
                in 
                connection with Realhub or on sites that may appear as links on
                Realhub, or in the products provided as a part of, or otherwise 
                in connection with, the Realhub. No oral advice or written
                information given by Realhub or any of its affiliates,
                employees, 
                officers, directors, agents, or the like will create a warranty.
              </p>
              <h4 class="mt-4">
                Advertising Disclosure
              </h4>
              <p>
                This website may contain third party advertisements and links to
                third party sites. Realhub does not make any representation as 
                to the accuracy or suitability of any of the information
                contained in those advertisements or sites and does not accept
                any 
                responsibility or liability for the conduct or content of those
                advertisements and sites and the offerings made by the third
                parties.
              </p>
              <p>
                Advertising keeps Realhub and many of the websites and services
                you use free of charge. We work hard to make sure that ads 
                are safe, unobtrusive, and as relevant as possible.
              </p>
              <p>
                Third party advertisements and links to other sites where goods
                or services are advertised are not endorsements or 
                recommendations by Realhub of the third party sites, goods or
                services. Realhub takes no responsibility for the content of any 
                of the ads, promises made, or the quality/reliability of the
                products or services offered in all advertisements.
              </p>
              <h4 class="mt-4">
                Testimonials Disclosure
              </h4>
              <p>
                Any testimonials provided on this platform are opinions of those
                providing them. The information provided in the testimonials is 
                not to be relied upon to predict results in your specific
                situation. The results you experience will be dependent on many
                factors 
                including but not limited to your level of personal
                responsibility, commitment, and abilities, in addition to those
                factors that you 
                and/or Realhub may not be able to anticipate.
              </p>
              <p>
                We will give honest testimonials to our visitors regardless of
                any discount. Any product or service that we test are individual 
                experiences, reflecting real life experiences. The testimonials
                could be displayed on audio, text or video and are not
                necessarily 
                representative of all of those who will use our products and/or
                services.
              </p>
              <p>
                Realhub does not guarantee the same results as the testimonials
                given on our platform. Testimonials presented on Realhub are 
                applicable to the individuals writing them, and may not be
                indicative of future success of any other individuals.
              </p>
              <p>
                Please don’t hesitate to contact us if you would like to know
                more about testimonials, discounts, or any of the
                products/services 
                that we review.
              </p>
              <h4 class="mt-4">
                Your Consent
              </h4>
              <p>
                We&apos;ve updated our Disclaimer to provide you with complete
                transparency into what is being set when you visit our site and
                how 
                it&apos;s being used. By using our website, registering an
                account, or making a purchase, you hereby consent to our
                Disclaimer and 
                agree to its terms.
              </p>
              <h4 class="mt-4">
                Changes To Our Disclaimer
              </h4>
              <p>
                Should we update, amend or make any changes to this document so
                that they accurately reflect our Service and policies.
              </p>
              <p>
                Unless otherwise required by law, those changes will be
                prominently posted here. Then, if you continue to use the
                Service, you 
                will be bound by the updated Disclaimer. If you do not want to
                agree to this or any updated Disclaimer, you can delete your account.</p>
              <h4 class="mt-4">
                Contact Us
              </h4>
              <p>
                Don&apos;t hesitate to contact us if you have any questions
                regarding this Disclaimer.
              </p>
              <p>
                Via Email: support@realhub.co.ke
              </p>
              <p>
                Via Phone Number: +254 114 140 882
              </p>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
